import 'animate.css'
import { QRCodeSVG } from 'qrcode.react'
import { useEffect, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import ToggleButton from '../guideToggleButton'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'
import { useIsDesktop } from '../useIsDesktop'
import { ImageUrl, useRwdImages } from '../useRwdImages'
import './App.css'

const mobileImgURLs: ImageUrl[] = [
  { key: 'bg', url: '4d3adcaa-df6d-43b0-abce-f9a8de45a23a' }, //bg.png
  { key: 'btn-download', url: '962c945b-3182-4784-a656-e07a1c882ddc' }, //btn-download
  { key: 'download-bar', url: 'd8576601-e589-4acd-864a-8f790f8ab9d6' }, //download-bar
  { key: 'icon-heart', url: 'c7d78768-41ed-4ff8-bf34-f585a27965b1' }, //icon-heart
  { key: 'icon-share', url: '73b955ea-bc60-4abc-9d6b-aa43e86d8e83' }, //icon-share
  { key: 'ios', url: '786c470d-6a31-4113-a05b-168a1105daf1' }, //ios.png
  { key: 'logo-title', url: 'f516333b-1c91-47ba-bcb6-d6b329ef7ef4' }, //logo-title
  { key: 'slider1', url: '96ad4de4-0a19-4048-92e1-ccc5ffec09d8' }, //slider1
  { key: 'slider2', url: '237a5620-05be-4dbd-a976-124cdea18874' }, //slider2
  { key: 'left-img', url: '1fe1af2a-ec0c-4c53-b933-986ee30a2c41' }, //left-img
  { key: 'ios', url: '3278adea-5322-4e64-9038-7a1663fab92e' }, //ios.png
  {
    key: 'android',
    url: '264613a5-bf46-451e-9f65-18cbb1e08b20',
  }, //android.png
]

const desktopImgURLs: ImageUrl[] = [
  { key: 'bg', url: '9047b2d9-423b-4feb-8256-7dc43210f613' },
  { key: 'btn-download', url: '962c945b-3182-4784-a656-e07a1c882ddc' },
  { key: 'download-bar', url: '04956066-86ff-4dfb-8ace-2a15bfbacb1f' },
  { key: 'icon-heart', url: '5440aa05-0e31-477c-a054-64d045544d38' },
  { key: 'icon-share', url: 'c3a22647-93ae-4651-ab45-d49af2ebaa35' },
  { key: 'logo-title', url: 'f516333b-1c91-47ba-bcb6-d6b329ef7ef4' },
  { key: 'slider1', url: '96ad4de4-0a19-4048-92e1-ccc5ffec09d8' },
  { key: 'slider2', url: '237a5620-05be-4dbd-a976-124cdea18874' },
  { key: 'left-img', url: '1fe1af2a-ec0c-4c53-b933-986ee30a2c41' }, //left-img
  { key: 'ios', url: 'e9d5d815-8436-4e67-9359-31ddc948946a' }, //ios.png
  {
    key: 'android',
    url: '70d513bd-9bfd-443d-9bb8-88b8efa1a4c0',
  }, //android.png
]

function Landing2(props: any) {
  const [images, setImages] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(true)
  const [isAndroid, setIsAndroid] = useState(false)
  const isDesktop = useIsDesktop(1024) // 設定 1024px 作為斷點
  const [imgURLs] = useRwdImages(mobileImgURLs, desktopImgURLs, isDesktop)

  useEffect(() => {
    // 檢測裝置類型
    const userAgent = navigator.userAgent
    if (/android/i.test(userAgent)) {
      setIsAndroid(true)
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      setIsAndroid(false)
    }

    if (props?.dl?.length) {
      const cdn = props.dl[0]
      Promise.all(
        imgURLs.map((item) => fetch(`https://${cdn}/images/${item.url}`))
      ).then(async (res) => {
        const newImages: { [key: string]: string } = {}
        for (let i = 0; i < res.length; i++) {
          let str = await res[i].text()
          str = getSrc(str)
          newImages[imgURLs[i].key] = str
        }
        setImages(newImages)
        setIsLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgURLs])

  const renderSwiper = () => (
    <div className="lg:max-w-[30%]">
      <Swiper
        spaceBetween={0}
        speed={800}
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 2000,
        }}
        modules={[Autoplay]}>
        <SwiperSlide key={0}>
          <img src={`data:image/png;base64,${images['slider1']}`} alt="" />
        </SwiperSlide>
        <SwiperSlide key={1}>
          <img src={`data:image/png;base64,${images['slider2']}`} alt="" />
        </SwiperSlide>
        <SwiperSlide key={2}>
          <img src={`data:image/png;base64,${images['slider1']}`} alt="" />
        </SwiperSlide>
        <SwiperSlide key={3}>
          <img src={`data:image/png;base64,${images['slider2']}`} alt="" />
        </SwiperSlide>
      </Swiper>
    </div>
  )

  const renderHeader = () => {
    return (
      <div className="relative flex h-20 w-full items-center justify-center bg-gradient-to-t from-transparent to-[#001A41]">
        <img
          src={`data:image/png;base64,${images['logo-title']}`}
          alt="Logo Title"
          className="absolute left-[11px] top-[12px] z-20 h-[56px] w-auto"
        />
        <a
          href="https://t.me/IDS_Offical"
          target="_blank"
          rel="noopener noreferrer">
          <img
            src={`data:image/png;base64,${images['icon-heart']}`}
            alt="Heart"
            className="absolute right-[18%] top-[12px] w-[12%] cursor-pointer lg:right-[14%]"
          />
        </a>
        <a
          href="https://t.me/idsofficial"
          target="_blank"
          rel="noopener noreferrer">
          <img
            src={`data:image/png;base64,${images['icon-share']}`}
            alt="Share"
            className="absolute right-[11px] top-[12px] w-[12%] cursor-pointer"
          />
        </a>
      </div>
    )
  }

  const renderQrCode = () => {
    return (
      <div className="absolute bottom-[7%] left-[32%] bg-white p-2">
        <QRCodeSVG value={window.location.href} size={80} />
      </div>
    )
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="App2 m-auto pb-6 md:max-w-[640px] lg:max-w-full">
      <img
        src={`data:image/png;base64,${images['bg']}`}
        alt="Background"
        className="fixed left-1/2 top-0 z-0 h-full w-full -translate-x-1/2 transform object-cover md:max-w-[640px] lg:max-w-full"
        onClick={onClickDownload}
      />

      {renderHeader()}
      <div className="md:inherit lg:flex lg:justify-around lg:p-10">
        {isDesktop && (
          <div className="relative lg:h-max lg:max-w-[60%]">
            <img src={`data:image/png;base64,${images['left-img']}`} alt="" />
            {renderQrCode()}
          </div>
        )}
        {renderSwiper()}
      </div>
      {!isDesktop && (
        <img
          src={`data:image/png;base64,${images['btn-download']}`}
          alt="Download Button"
          className=" relative z-30 mx-auto mt-4 w-2/3"
          onClick={() => {
            // 像素追蹤
            let pixelUrl =
              'https://hr1v8.bemobtrcks.com/conversion.gif?cid=OPTIONAL&payout=OPTIONAL&txid=OPTIONAL&status=OPTIONAL'
            const img = new Image()
            img.src = pixelUrl
            img.width = 1
            img.height = 1
            img.style.display = 'none'
            document.body.appendChild(img)
            onClickDownload()
          }}
        />
      )}
      <div className="flex-1 items-center overflow-y-auto p-4">
        <ToggleButton isAndroid={isAndroid} setIsAndroid={setIsAndroid} />
        <div
          className={`lg:m-auto ${
            isAndroid ? 'lg:max-w-[600px]' : 'lg:max-w-[1200px]'
          }`}>
          <img
            alt=""
            src={
              isAndroid
                ? `data:image/png;base64,${images['android']}`
                : `data:image/png;base64,${images['ios']}`
            }
            className="relative w-full rounded-lg shadow-lg"
          />
        </div>
      </div>
      <div className="h-[10vw]" />
      {!isDesktop && (
        <img
          src={`data:image/png;base64,${images['download-bar']}`}
          sizes="100%"
          alt="Download Button"
          className="fixed bottom-0 z-30 mx-auto md:max-w-[640px]"
          onClick={onClickDownload}
        />
      )}
    </div>
  )
}

export default Landing2
