import { useEffect, useState } from 'react'

export interface ImageUrl {
  key: string
  url: string
}

export const useRwdImages = (
  mobileImages: ImageUrl[] = [],
  desktopImages: ImageUrl[] = [],
  isDesktop: boolean
): [ImageUrl[], boolean] => {
  const [imgURLs, setImgURLs] = useState<ImageUrl[]>(
    isDesktop ? desktopImages : mobileImages
  )

  const handleResize = () => {
    setImgURLs(isDesktop ? desktopImages : mobileImages)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop, mobileImages, desktopImages]) // 確保依賴最新狀態

  return [imgURLs, isDesktop]
}
