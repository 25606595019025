import { useEffect, useState } from 'react'

export const useIsDesktop = (breakpoint: number = 1024): boolean => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > breakpoint)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > breakpoint)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoint])

  return isDesktop
}
