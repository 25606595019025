import React from 'react'
// @ts-ignore
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import GuideAndroid from './guideAndroid'
import GuideIos from './guideIos'
import App1 from './landing1'
import App2 from './landing2'
import App3 from './landing3'
import { getStaticUrl } from './lib'

import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)

const devUrls = 'https://dev-wl-tw2-landing.hmtech-dev.com'
// const host = devUrls;
const host = (() => {
  // 檢查 window.location.href 是否包含 '.html'，是的話就找出最後一個 / 的位置，並取出前面的字串
  if (window.location.href.includes('.html')) {
    return window.location.href.slice(0, window.location.href.lastIndexOf('/'))
  }

  // 檢查 window.location.href 的最後一個字元是否為 '/'，是的話就去掉
  if (window.location.href.endsWith('/')) {
    return window.location.href.slice(0, -1)
  }

  // 檢查 window.location.href 是否包含 '?'，是的話就找出最後一個 / 的位置，並取出前面的字串
  if (window.location.href.includes('?')) {
    return window.location.href.slice(0, window.location.href.lastIndexOf('/'))
  }

  return window.location.href
})()

const urls = [`${host}/dl.json`]
console.log('host', host)

Promise.all(urls.map((url) => fetch(url).then((res) => res.json()))).then(
  (res) => {
    const { dl } = res[0]
    console.log('res!!!', dl)

    var hostname = host
    var startIndex = hostname.indexOf('//') + 2
    var urlAfterDoubleSlash = hostname.substring(startIndex)
    var subdomain = urlAfterDoubleSlash.split('.')[0]

    getStaticUrl(host, subdomain).then((res) => {
      if (!res) return

      const script = document.createElement('script')
      script.innerHTML = `LA.init({id:'${res.id}',ck:'${res.ck}'})`
      document.head.appendChild(script)
    })

    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            <Route path="/p1.html" element={<App1 dl={dl} />} />
            <Route path="/p2.html" element={<App2 dl={dl} />} />
            <Route path="/p3.html" element={<App3 dl={dl} />} />
            <Route path="/guide-ios.html" element={<GuideIos />} />
            <Route path="/guide-android.html" element={<GuideAndroid />} />

            <Route path="*" element={<App1 dl={dl} />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    )
  }
)
