import asiax from '@asia-x/sdk'
import { useEffect, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getSrc, onClickDownload } from '../lib'
import { LoadingSpinner } from '../loadingSpinner'
import './App.css'

const imgURLs = [
  { key: 'logo', url: '4dd31b31-77e4-487e-ae8c-cc18e9760c25' }, //2.png
  { key: 'title', url: 'b5904dd6-1b5e-4220-90d0-0c01defa7fc0' }, //bg.png
  { key: 'img_ad_03', url: '9d26c58c-655f-485b-be51-da672f119fdf' },
  { key: 'img_ad_04', url: '214cf0e7-c15a-45ac-b93d-c75f287ddeef' },
  { key: 'img_ad_05', url: 'a08f8238-e97c-40b6-a716-e3b3fe2a62c4' },
  { key: 'img_ad_06', url: '7e75dd71-ea40-4afb-b26c-d27653da3df4' },
  { key: 'img_ad_07', url: '53f6ae45-569f-485d-813b-1dcb2a2645ac' },
  { key: 'img_ad_08', url: 'a1a6add8-5f39-4961-b608-e3c1783a1274' },
  { key: 'img_ad_09', url: '50ab9dfd-c988-461d-8d4e-a5b2deba81f4' },
  { key: 'img_ad_02', url: '3bb5c905-2cdf-4e16-866a-01c313ddf96a' },
  { key: 'img_ad_01', url: '579d943a-ccee-441f-b9a0-fedd3bd4e9a0' },
  { key: 'download-bg', url: '9f69fe39-93ec-4925-8f3e-cd139d9aa469' },
]

function Landing3(props: any) {
  const [images, setImages] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!isLoading && props?.dl?.length) {
      asiax.init({
        elementId: 'sdk-app',
        apiBaseUrl: 'https://web.axptv.com/api',
        merchantId: 'mlc',
        merchantKey: '42ETVQHLDaOvm57U4zVToHb3Cf8x5rzE',
        displayDemoController: false,
        displaySearchBar: false,
        displayLanguageSetButton: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (props?.dl?.length) {
      const cdn = props.dl[0]
      Promise.all(
        imgURLs.map((item) => fetch(`https://${cdn}/images/${item.url}`))
      ).then(async (res) => {
        const newImages: { [key: string]: string } = {}
        for (let i = 0; i < res.length; i++) {
          let str = await res[i].text()
          str = getSrc(str)
          newImages[imgURLs[i].key] = str
        }
        setImages(newImages)
        setIsLoading(false)
      })
    }
  }, [props])

  const renderIOSSwiper = () => (
    <div>
      <Swiper pagination={true} modules={[Pagination]}>
        {[
          'img_ad_01',
          'img_ad_02',
          'img_ad_03',
          'img_ad_04',
          'img_ad_05',
          'img_ad_06',
          'img_ad_07',
          'img_ad_08',
          'img_ad_09',
        ].map((key, index) => (
          <SwiperSlide key={index}>
            <div className="relative" onClick={onClickDownload}>
              <img src={`data:image/png;base64,${images[key]}`} alt={key} />

              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  width: '100%',
                }}>
                <img
                  src={`data:image/png;base64,${images['logo']}`}
                  className="mx-auto mb-1"
                  alt="Download Button"
                  style={{
                    width: '80px',
                  }}
                />
                <img
                  src={`data:image/png;base64,${images['title']}`}
                  sizes="100%"
                  alt="Download Button"
                  className="mx-auto w-[150px]"
                  style={{
                    width: '150px',
                  }}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="App m-auto pb-12 md:max-w-[640px] ">
      {renderIOSSwiper()}
      <div id="sdk-app" />
      <img
        src={`data:image/png;base64,${images['download-bg']}`}
        sizes="100%"
        alt="Download Button"
        className=" fixed bottom-0 z-30 mx-auto md:max-w-[640px]"
        onClick={onClickDownload}
      />
    </div>
  )
}

export default Landing3
