import { FaAndroid, FaApple } from 'react-icons/fa'

interface ToggleButtonProps {
  isAndroid: boolean
  setIsAndroid: (value: boolean) => void
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  isAndroid,
  setIsAndroid,
}) => {
  return (
    <div className=" mb-4 flex justify-center ">
      <div className="z-20 flex w-72 rounded-full bg-[#1A3253] ">
        <button
          className={`flex w-1/2 items-center justify-center rounded-full py-2 ${
            !isAndroid
              ? 'bg-gradient-to-b from-[#FFE485] to-[#FFC700] text-[#1A3253]'
              : 'bg-[#1A3253] text-white'
          }`}
          onClick={() => setIsAndroid(false)}>
          <FaApple className="mr-2" />
          IOS
        </button>
        <button
          className={`flex w-1/2 items-center justify-center rounded-full py-2 ${
            isAndroid
              ? 'bg-gradient-to-b from-[#FFE485] to-[#FFC700] text-[#1A3253]'
              : 'text-white'
          }`}
          onClick={() => setIsAndroid(true)}>
          <FaAndroid className="mr-2" />
          Android
        </button>
      </div>
    </div>
  )
}

export default ToggleButton
