import React, { useState, useEffect, useRef } from "react";
import { getSrc } from "./lib";
import { ClipLoader } from "react-spinners"; // 加載指示器

interface ImageData {
  key: string;
  url: string;
}

interface ImageRendererProps {
  image: ImageData;
  dl?: string[];
  className?: string;
}

const imageCache: { [key: string]: string } = {}; // 圖片緩存

const ImageRenderer: React.FC<ImageRendererProps> = ({
  image,
  dl,
  className,
}) => {
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    const loadImage = async () => {
      if (imageCache[image.key]) {
        // 如果圖片已在緩存中，直接使用
        if (isMounted.current) {
          setCurrentImage(imageCache[image.key]);
          setIsLoading(false);
        }
      } else if (dl && dl.length > 0) {
        setIsLoading(true);
        const cdn = dl[0];
        try {
          const response = await fetch(`https://${cdn}/images/${image.url}`);
          let text = await response.text();
          const src = getSrc(text);
          imageCache[image.key] = src; // 將圖片添加到緩存
          if (isMounted.current) {
            setCurrentImage(src);
            setIsLoading(false);
          }
        } catch (error) {
          console.error("Error loading image:", error);
          if (isMounted.current) {
            setIsLoading(false);
          }
        }
      }
    };

    loadImage();

    return () => {
      isMounted.current = false;
    };
  }, [image, dl]);

  return (
    <div className={`relative ${className || ""}`}>
      {isLoading && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-gray-800 bg-opacity-50">
          <ClipLoader color="#ffffff" size={50} />
        </div>
      )}
      {currentImage && (
        <img
          src={`data:image/png;base64,${currentImage}`}
          alt={image.key}
          className={`h-auto w-full transition-opacity duration-300 ${
            isLoading ? "opacity-0" : "opacity-100"
          }`}
        />
      )}
    </div>
  );
};

export default ImageRenderer;
