import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'

interface FullscreenImageProps {
  image: string | null
  onClose: () => void
}

const FullscreenImage: React.FC<FullscreenImageProps> = ({
  image,
  onClose,
}) => {
  if (!image) return null

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
      onClick={handleBackgroundClick}>
      <button onClick={onClose} className="absolute right-4 top-4 text-white">
        <XMarkIcon className="h-6 w-6" />
      </button>
      <img
        src={image}
        alt="Fullscreen"
        className="max-h-[90vh] max-w-[90vw] object-contain"
        onClick={(e) => e.stopPropagation()} // 防止點擊圖片時關閉
      />
    </div>
  )
}

export default FullscreenImage
