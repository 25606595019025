import { ArrowLeftIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import FullscreenImage from '../fullscreenImage'

const GuideAndroid = () => {
  const navigate = useNavigate()
  const [fullscreenImage, setFullscreenImage] = useState<string | null>('')

  const guides = [
    {
      os: 'ios',
      images: [
        'images/guide/guide-ios-01.png',
        'images/guide/guide-ios-02.png',
      ],
      icon: 'images/guide/icon-ios.png',
    },
    {
      os: 'android',
      images: ['images/guide/guide-android.png'],
      icon: 'images/guide/icon-android.png',
    },
  ]

  return (
    <div className="flex h-screen flex-col bg-[#001a40]">
      {/* Header */}
      <header className="flex items-center justify-between p-4 text-white">
        <button onClick={() => navigate('/')} className="z-10">
          <ArrowLeftIcon className="h-6 w-6" />
        </button>
        <div className="absolute left-0 right-0 text-center font-bold">
          Install Guide
        </div>
      </header>

      {/* Content */}
      <div className="flex-1 overflow-y-auto p-4">
        {guides
          .filter((guide) => guide.os === 'android')
          .map((guide) => (
            <div key={guide.os} className="space-y-4">
              {guide.images.map((image, index) => (
                <div
                  key={index}
                  className="flex aspect-video cursor-pointer items-center justify-center"
                  onClick={() => setFullscreenImage(image)}>
                  <img src={image} alt="android" className="w-full" />
                </div>
              ))}
            </div>
          ))}
      </div>

      {/* Fullscreen Image */}
      <FullscreenImage
        image={fullscreenImage}
        onClose={() => setFullscreenImage(null)}
      />
    </div>
  )
}

export default GuideAndroid
